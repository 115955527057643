import React from "react"
import Fade from "react-reveal/Fade"

const FeatureSection = () => {
  return (
    <div className="max-w-7xl mx-auto ">
      <Fade bottom cascade>
        <div className="mt-5 flex flex-row justify-space xxs:flex-col xs:flex-col sm:flex-row">
          <div className="w-2/6 p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-2/6">
            <h1 className="text-gray text-4xl">Stats</h1>
            <div className="mt-5">
              <div>
                <h2 className="text-black text-xl">Year Founded</h2>
                <h1 className="text-black text-sm">2019</h1>
              </div>
              <div className="mt-5">
                <h2 className="text-black text-xl">Total Sales</h2>
                <h1 className="text-black text-sm">$200,000</h1>
              </div>
            </div>
          </div>
          <div className="w-4/6 bg-white p-8 rounded-xl m-5 xxs:w-full xs:w-full sm:w-4/6">
            <h1 className="text-black text-4xl">About Us</h1>
            <p className="mt-10 text-black opacity-100">
            Lapis Bazaar was established in 2019 and originally sold replacement watchband straps on Amazon and Ebay, totaling over $200,000 in sales. We are currently in transition from watchbands to personalized jewelry.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default FeatureSection
