import React from "react"
import Input from "../Atoms/input"
import Button from "../Atoms/button"
import Fade from "react-reveal/Fade"

// Assets
import HeaderImage from "../../images/header-main.jpg"

const Header = () => {
  return (
    <div className="max-w-7xl mx-auto px-8">
      <div class="relative overflow-hidden">
        <img src={HeaderImage} />
        <div class="ml-7 mb-2 absolute font-poppins w-full py-1.5 bottom-7 bg-black bg-opacity-50 bg-clip-text inset-x-0 bg-transparent text-white text-left font-semibold text-2xl xxs:text-2xl xs:text-2xl sm:text-lg md:text-2xl lg:text-4xl xl:text-7xl 2xl:text-8xl">
          <span>We bring the best artisan goods<br /></span>
          from Turkish craftsmen <br />
          to American markets.
        </div>
      </div>
    </div>
  )
}

export default Header
